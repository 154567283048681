import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import ShinyButton from '../../components/ShinyButton';
import AppForm from '../../components/AppForm';
import AppInput from '../../components/AppInput';
import { useForm } from 'react-hook-form';

import './style.scss';

const ArtDirectorPage = () => {
    const { getValues, setValue } = useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [dataFromlocalStorage, setdataFromlocalStorage] = useState();
    const [resumeFileName, setResumeFileName] = useState('');

    useEffect(() => {
        retrieveDataFromLocalStorage();
    }, [currentPage]);

    const retrieveDataFromLocalStorage = () => {
        if (localStorage.getItem('resumeData') !== null) {
            const savedValues = JSON.parse(localStorage.getItem('resumeData'));
            setdataFromlocalStorage(savedValues);
            for (let key in savedValues) {
                setValue(key, savedValues[key]);
            }
        }
    };

    const checkUploadedFileSize = file => {
        if (file.size > 52428800) {
            alert('File is too big! Max resume size is 50MB.');
            file.value = '';
            return false;
        }
        return true;
    };

    const setUploadedFileName = (resume, file) => {
        if (checkUploadedFileSize(file[0])) {
            const selectedFile = file[0].name.split('\\');
            localStorage.setItem('resumeData', JSON.stringify(getValues()));

            if (resume === 'resume') {
                setValue('resume', file);
                setResumeFileName(
                    'File Uploaded: ' + selectedFile[selectedFile.length - 1]
                );
            }
        }
    };

    const uploadResume = () => {
        const resumeUpload = document.getElementById('resume');
        resumeUpload.click();
    };

    return (
        <Layout>
            <Seo title="Art Director" />
            <div className="art-director-page">
                <Container className="purple-gradient-top container-1 centered-text ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <h1>Art Director</h1>
                        </Col>
                    </Row>
                </Container>
                <Container className=" container-2 centered-text ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <h3>Who we’re looking for</h3>
                            <p className=" wrapLineSib">
                                To be a Senior Graphic Designer at a GlassHive
                                you must have a keen eye and an unwavering
                                instinct for consistent quality. As the Senior
                                Graphic Deisnger, you’ll be the point-person
                                creating amazing visuals focused on our software
                                and its users for use in marketing.
                            </p>
                            <p>
                                We are looking for a Designer who is excellent
                                at working alongside fellow creatives. This
                                position requires you to follow and add to our
                                existing branding guidelines. Having outstanding
                                interpersonal skills as well as being able to
                                clearly communicate ideas is necessary.
                            </p>
                            <p>
                                Besides that, being patient, hard-working, and
                                down for a good time is always a plus.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className=" container-3 purple-gradient-bottom">
                    <Row>
                        <Col className=" transparent-box max-width-900 margin-auto">
                            <h3>Responsibilities</h3>
                            <li className=" wrapLineSib">
                                Create engaging and creative graphics that
                                visually represent our brand and software.
                            </li>
                            <li>
                                Oversee all design projects, from conception to
                                delivery
                            </li>
                            <li>
                                Design original pieces, including illustrations,
                                brochures, website pages, and other marketing
                                material
                            </li>
                            <li>
                                Review junior designers’ work to ensure high
                                quality
                            </li>
                            <li>
                                Generate ideas to portray concepts and advertise
                                products/services
                            </li>
                            <li>
                                Increase user friendliness in digital products
                                Maintain brand consistency throughout all our
                                marketing projects
                            </li>
                            <li>
                                Liaise with marketing and design teams to ensure
                                deadlines are met
                            </li>
                            <li>
                                Stay up-to-date with industry developments and
                                tools
                            </li>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="transparent-box-2 max-width-900 margin-auto">
                            <h3>Required Skills</h3>
                            <li className=" wrapLineSib">
                                5+ years experience in graphics design
                            </li>
                            <li>
                                Knowledge in design principles, including
                                layout, typography, color, composition, and
                                current design trends is needed
                            </li>
                            <li>
                                Design original pieces, including illustrations,
                                brochures, website pages, and other marketing
                                material
                            </li>
                            <li>
                                High level knowledge in design, web and print
                            </li>
                            <li>
                                Demonstrate a high level of proficiency in
                                designing softwares such as Photoshop,
                                Illustrator, InDesign and Adobe XD
                            </li>
                            <li>
                                Experience in designing marketing materials for
                                B2B (Business to Business)
                            </li>
                            <li>
                                Ability to function as a contributing team
                                member and interact positively and openly with
                                peers and other departments
                            </li>
                            <li>
                                Demonstrate strong problem solving,
                                communication, organizational, and multi-tasking
                                skills
                            </li>
                            <li>
                                Ability to learn, take direction and handle
                                critiques
                            </li>
                            <li>
                                Ability to work independently and in a team
                                environment
                            </li>
                            <li>
                                Ability to lead small projects with a design
                                team
                            </li>
                            <li>Good written and oral communication skills</li>
                            <li>Time Management Skills</li>
                        </Col>
                    </Row>
                </Container>
                <Container className=" container-4  ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <AppForm
                                formName="careers-form"
                                submitBtnText="Send"
                                className="pink-form careers-form"
                                formCategory="Careers"
                            >
                                <h3 class="centered-text">
                                    Apply now for Art Director
                                </h3>
                                <AppInput
                                    fieldName="firstName"
                                    type="text"
                                    labelText="What's your name?"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="email"
                                    type="email"
                                    labelText="What's your email address?"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="phone"
                                    type="text"
                                    labelText="What's your phone number?"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="why"
                                    type="text"
                                    labelText="Why do you want to work for GlassHive?"
                                    requiredField
                                />

                                <AppInput
                                    fieldName="goal"
                                    type="text"
                                    labelText="What do you want to achieve with a career at GlassHive?"
                                    requiredField
                                />

                                <AppInput
                                    fieldName="passion"
                                    type="text"
                                    labelText="What are you passionate about? (Doesn't have to be business related)"
                                    requiredField
                                />

                                <AppInput
                                    fieldName="movie"
                                    type="text"
                                    labelText="What's your favorite movie?"
                                />
                                <div className="file centered-text">
                                    <label
                                        htmlFor="resume"
                                        className="custom-file-upload"
                                    >
                                        <input
                                            type="file"
                                            name="resume"
                                            id="resume"
                                            className="file-upload"
                                            required
                                            onChange={e => {
                                                setUploadedFileName(
                                                    'resume',
                                                    e.target.files
                                                );
                                            }}
                                        />
                                        <p className="choose-a-file">
                                            Drop video resume/cover letter here
                                            or
                                            <ShinyButton
                                                className="btn white create-free-account-button"
                                                onClick={() => uploadResume()}
                                            >
                                                Select Files
                                            </ShinyButton>
                                        </p>
                                        <p id="fileUploadedName">
                                            {resumeFileName}
                                        </p>
                                    </label>
                                </div>
                                <AppInput
                                    fieldName="video"
                                    type="text"
                                    labelText="Video or Portfolio URL"
                                />
                            </AppForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default ArtDirectorPage;
